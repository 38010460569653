<template>
  <UiActionBar
    v-model="modelValue"
    :title="`${selectedIndexes.filter((s) => s).length} leads selected`"
    secondary-button-text="Cancel"
    @cancel="$emit('cancel')"
  >
    <div class="flex flex-row items-center gap-4">
      <UiTooltip name="reopen" position-top :width="200">
        <template #activator>
          <button
            id="reopen"
            :disabled="closedWonLeadsAreSelected"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            :class="{ '!cursor-default !text-black-40': closedWonLeadsAreSelected }"
            @click="showReopenPopup"
          >
            <UiIcon name="user"></UiIcon>
          </button>
        </template>
        <template #content>
          {{
            closedWonLeadsAreSelected
              ? 'Some closed won leads are selected'
              : 'Reopen and assign user to the selected leads'
          }}
        </template>
      </UiTooltip>
    </div>
  </UiActionBar>
</template>

<script setup lang="ts">
import { POPUPS } from '~/components/dynamic/maps'
import { useUiStore } from '~/store/ui'
import type { Lead } from '~/types'
import { ARCHIVES_STAGES } from '~/constants'

const modelValue = defineModel<boolean>()

const emits = defineEmits(['input', 'cancel'])

const uiStore = useUiStore()

type Props = {
  leads: Lead[]
  selectedIndexes: boolean[]
}
const props = defineProps<Props>()

const closedWonLeadsAreSelected = computed(() =>
  props.leads.some((lead: Lead, i: number) => props.selectedIndexes[i] && lead.step?.code === ARCHIVES_STAGES.WON)
)

const showReopenPopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_REOPEN,
    {
      selectedLeads: props.leads.filter((_: Lead, i: number) => props.selectedIndexes[i]),
    },
    {
      input: () => {
        emits('input')
        modelValue.value = false
      },
    }
  )
}
</script>

<style scoped></style>
